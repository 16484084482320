$(function(){

// mobile menu 用のタグを書き込む
$('body').append( '<div id="mobile_menu_wrapper"></div>' );

// overlay 用のタグを書き込む
$('body').append( '<div class="drawer-menu-overlay" id="overlay"></div>' );


// nav のソースをコピーして mobile 用の class を付与する
$('#nav').clone().addClass('sm_nav').appendTo('#mobile_menu_wrapper');

$('.sm_nav > ul > li > div > span, .sm_nav > ul > li > div > a').unwrap();

// trigger 用の class を付与する
$('.sm_nav .trigger').addClass('js-trigger');

// slideToggle 用に id を付ける
$('.sm_nav .global_navigation_menu').attr('id', 'sm_global_navigation_menu');

// burger-wrapper をクリックしたら open-menu class を付与
$('#js-burger').on( 'click', function(){
  $('body').toggleClass( 'open-menu' );
  $('#sm_global_navigation_menu').slideToggle(300);
});

// オーバーレイをクリックしたらメニューが消える
$('#overlay').on( 'click', function() {
  $('body').removeClass( 'open-menu' );
});

// 子メニューのアクション
$(document).on( 'click', '.js-trigger', function() {
  $(this).next('ul.child').slideToggle( 200 );
});

}); // end
