$(function(){

/**
*  TAB
*/

const tab = {
  list    : $('#tab'),
  label   : $('.js-tab'),
  content : $('#tab_view'),
  current : 'tab_current',
  fade    : 100
}

tabContentView();
console.log( $( '.' + tab.current ) );
tab.label.on('click', function(){

  /** current クラスを付ける */
  // current クラスを削除する
  tab.list.find(tab.label).removeClass(tab.current);
  // クリックした対象に current クラスを付ける
  $(this).addClass(tab.current);

  tabContentView();

});

function tabContentView()
{
  const view = $( '.' + tab.current ).attr('data-label');
  tab.content.find('>*').hide();
  $( '#' + view ).fadeIn(tab.fade);
}



/**
*  PAGE TOP
*/

const pagetop = $('#pageTop');

$(window).on('scroll', function () {
  if( $(this).scrollTop() > 100 ) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }
});
//
pagetop.on('click', function(){
  $('body, html').animate({ scrollTop : 0 }, 400);
  return false;
});



/**
*  ACCORDION
*/

$('.accordion dt').on('click', function(){
  $(this).next('dd').slideToggle(180);
  $(this).toggleClass('open');
  $(this).next('dd').siblings('dd').slideUp(100);
  $(this).siblings('dt').removeClass('open');
});


});
